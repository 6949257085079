<template>
  <div class="">
    <div class="body">
      <div class="container">
        <h1>联系我们</h1>
        <p> <span class="title">电话</span>：<span class="phone">4001162696</span></p>
        <p><span class="title">地址</span>：北京市朝阳区南新园西路香榭舍21A6、A7</p>
        <p><span class="title">邮箱</span>：centvguoxue@sina.com</p>
        <p><span class="title">网址</span>：<a href="http://www.jingshangw.com/" target="_blank">http://www.jingshangw.com/</a> </p>
        <div id="map-container"></div>
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>

<script>

export default {
  name: "contact",
  setup () {
    const initMap = () => {

      const center = new window.TMap.LatLng(39.869556, 116.4655);//设置中心点坐标
      //初始化地图
      const map = new window.TMap.Map("map-container", {
        center: center,
        zoom: 16
      });

      new window.TMap.MultiMarker({
        id: 'marker-layer',
        map: map,
        styles: {
          "marker": new window.TMap.MarkerStyle({
            "width": 24,
            "height": 35,
            "anchor": { x: 12, y: 35 },
            "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
          })
        },
        geometries: [{
          "id": 'demo1',
          "styleId": 'marker',
          "position": new window.TMap.LatLng(39.869556, 116.4655),
          "properties": {
            "title": "marker"
          }
        }]
      });

      //初始化infoWindow
      // new TMap.InfoWindow({
      //   map: map,
      //   position: center, //设置信息框位置
      //   content: 'Hello World!' //设置信息框内容
      // });

    }
    const apiScript = document.createElement('script')
    apiScript.src = 'https://map.qq.com/api/gljs?v=1.exp&key=MJKBZ-NC2KF-ZJ5JS-NNPKV-WL6ZK-JFB4I'
    apiScript.addEventListener('load', initMap)
    document.body.appendChild(apiScript)
  }
};
</script>


<style scoped lang="stylus">
.body {
  height: 100%;
  // background: #fff;
}

.container {
  padding: 20px 0;

  h1 {
    text-align: center;
  }

  p {
    font-size: 15px;
    margin-bottom: 10px;
    color: #666;
  }

  .title {
    color: #444;
  }

  .phone {
    color: #f41922;
  }
}

.map-container {
  height: 600px;
}
</style>
